import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from "../components/Button";
import HiloLogo from "../assets/hilo-logo.webp";

import { LoginDecoBottom, LoginDecoTop } from '../assets/LoginDeco.tsx';
import AuthContext from '../components/AuthProvider';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';


type CustomFormEvent = React.FormEvent<HTMLFormElement> & {
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Login = () => {
  const {loginUser} = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const handleSteamLogin = (e: any) => {
    e.preventDefault();
    const steamBaseUrl = "https://steamcommunity.com/openid/login";
    
    const returnUrl = `${process.env.REACT_APP_FRONTEND_URL}/steam-callback`;
    
    const steamLoginUrl = `${steamBaseUrl}?` + new URLSearchParams({
      "openid.ns": "http://specs.openid.net/auth/2.0",
      "openid.claimed_id": "http://specs.openid.net/auth/2.0/identifier_select",
      "openid.identity": "http://specs.openid.net/auth/2.0/identifier_select",
      "openid.return_to": returnUrl,
      "openid.realm": process.env.REACT_APP_FRONTEND_URL || "",
      "openid.mode": "checkid_setup",
    }).toString();
    
    window.location.href = steamLoginUrl;
  };

  useEffect(() => {
    const referCode = searchParams.get("refer");
    if (referCode) {
      localStorage.setItem("referral_code", referCode);
    } else {
      localStorage.removeItem("referral_code");
    }
  }, [searchParams])

  return (
    <div className="h-screen w-full flex flex-col gap-4">
      <a
        className="sm:m-8 hover:cursor-pointer"
        href="https://www.hilogaming.com"
      >
        <img src={HiloLogo} className="h-14 sm:h-20 sm:pl-6 pl-2" alt="HiLo" />
      </a>
      <span className="bottom-0 left-0 fixed invisible sm:visible">
        <LoginDecoBottom />
      </span>
      <span className="top-0 right-0 fixed invisible sm:visible">
        <LoginDecoTop />
      </span>
      <div className="flex items-center justify-center pb-12">
        <div className="w-full sm:max-w-3xl bg-transparent border-4 border-hiloLoginBorder rounded-login shadow mx-4 px-12 sm:px-32 py-12 justify-center z-40">
          <form className="space-y-4" onSubmit={(e) => {
            setLoading(true);
            loginUser({
              ...e,
              setLoading,
            } as CustomFormEvent);
            loginUser(e);
          }}>
            <div className="flex flex-col gap-2">
              <h5 className="text-xl sm:text-3xl text-gray-400 font-bold">Log In</h5>
              <p className="text-white pb-2 text-sm sm:text-base font-light">By continuing, you agree to our{' '}
                <a className="hover:cursor-pointer hover:underline text-cyan-400" href="https://www.hilogaming.com/terms-of-use">
                  {'User Agreement'}
                </a>
                {' '}and acknowledge that you understand the{' '}
                <a className="hover:cursor-pointer hover:underline text-cyan-400" href="https://www.hilogaming.com/privacy-policy">
                  {'Privacy Policy '}
                </a>
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="relative">
                <div className="absolute inset-y-4 start-0 flex items-center ps-3.5 pointer-events-none h-4">
                  <svg stroke="#A1A1A1" fill="#A1A1A1" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M332.64 64.58C313.18 43.57 286 32 256 32c-30.16 0-57.43 11.5-76.8 32.38-19.58 21.11-29.12 49.8-26.88 80.78C156.76 206.28 203.27 256 256 256s99.16-49.71 103.67-110.82c2.27-30.7-7.33-59.33-27.03-80.6zM432 480H80a31 31 0 0 1-24.2-11.13c-6.5-7.77-9.12-18.38-7.18-29.11C57.06 392.94 83.4 353.61 124.8 326c36.78-24.51 83.37-38 131.2-38s94.42 13.5 131.2 38c41.4 27.6 67.74 66.93 76.18 113.75 1.94 10.73-.68 21.34-7.18 29.11A31 31 0 0 1 432 480z"></path></svg>
                </div>
                <input type="username" name="username" id="username" className="pl-12 bg-transparent bg-login-user border border-gray-400 text-white text-base sm:text-lg rounded-2xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Email or username"
                  required
                />
              </div>
              <div className="relative">
                <div className="absolute inset-y-4 start-0 flex items-center ps-3.5 pointer-events-none h-4">
                  <svg stroke="#A1A1A1" fill="#A1A1A1" stroke-width="0" viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M6 9V7.25C6 3.845 8.503 1 12 1s6 2.845 6 6.25V9h.5a2.5 2.5 0 0 1 2.5 2.5v8a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 3 19.5v-8A2.5 2.5 0 0 1 5.5 9Zm-1.5 2.5v8a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-13a1 1 0 0 0-1 1Zm3-4.25V9h9V7.25c0-2.67-1.922-4.75-4.5-4.75-2.578 0-4.5 2.08-4.5 4.75Z"></path></svg>
                </div>
                <input type="password" name="password" id="password" placeholder="Password" className="pl-12 bg-transparent border-gray-400 text-white text-base sm:text-lg rounded-2xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
              </div>
            </div>
            <div className="flex flex-row gap-2">
              {/* <a
                // href="mailto:hilogaming.gg@gmail.com?subject=Forgot%20password&body=Hi,%20I%20forgot%20my%20password.%20Can%20you%20help%20me%20reset%20it?"
                className="text-base sm:text-lg text-[#85B6FF] hover:cursor-pointer font-extralight hover:underline"
                onClick={() => {
                  alert('Join our discord and contact us to have your passowrd reset!')
                }}
              >
                Forgot password?
              </a> */}
              <Link to = "/register">
                <p
                  className="text-base sm:text-lg text-[#85B6FF] hover:cursor-pointer font-extralight hover:underline"
                >
                  Register here!
                </p>
              </Link>
            </div>
            {(process.env.REACT_APP_DEPLOYED_ON_PROD === "False") && <span className="flex flex-row gap-2 items-center font-extralight">
              <p className="text-base sm:text-lg text-white">New to HiLo?</p>
              <Link to="/register">
                <p className="text-base sm:text-lg text-[#85B6FF]">
                  Sign Up
                </p>
              </Link>
            </span>}
            {(process.env.REACT_APP_DEPLOYED_ON_PROD === "True") && <div className="text-base sm:text-lg text-white inline-flex flex-wrap gap-1">
              <p>Join our</p>
              <a className="text-[#85B6FF] hover:cursor-pointer z-50 hover:underline"
                href="https://discord.gg/Gyvz4XYmJS"
              >
                {' '}Discord
              </a>
              <p>and be part of Team Alpha!</p>
            </div>}
            <div className="w-full z-50">
              <Button
                color="gradient" 
                style={{
                  width: "100%",
                  transition: "transform 0.2s ease-in-out, background-color 0.2s ease-in-out",
                }}
                onMouseEnter={(e) => {
                  const target = e.currentTarget as HTMLButtonElement;
                  target.style.setProperty("transform", "translateY(-6px)");
                }}
                onMouseLeave={(e) => {
                  const target = e.currentTarget as HTMLButtonElement;
                  target.style.setProperty("transform", "translateY(0)");
                }}
                type="submit">
                {(!loading) ? "Log In" : "Logging In..."}
              </Button>
            </div>
            <div className="w-full z-50">
              <button
                className="flex items-center justify-center text-white text-[18px] font-poppins gap-2 font-extrabold w-full border-[1px] rounded-3xl py-[12px] px-[24px] hover:bg-slate-400 hover:transform hover:translate-y-[-6px] transition-transform duration-200 ease-in-out"
                type="button"
                onClick={handleSteamLogin}
              >
                Signup/Login with Steam
                <div className="h-8 w-8 rounded-full overflow-hidden">
                  <img 
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Steam_icon_logo.svg/2048px-Steam_icon_logo.svg.png"
                    alt="Steam"
                    className="w-full h-full object-cover"
                  />
                </div>
              </button>
            </div>
            {/* <div className="w-full z-50">
              <Button 
                color="steam" 
                style={{width: "100%"}} 
                type="button"
                onClick={handleSteamLogin}
              >
                Login with Steam
              </Button>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;