import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import Button from "./Button.tsx";
import { getAuthHeader } from "../utils";
import AuthContext from "./AuthProvider";

import { AiOutlineLoading } from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import { IoIosHeartEmpty, IoIosHeart } from "react-icons/io";

import { timeAgo, extractUsername } from "../utils";

import CommendToolTip from "./CommendToolTip";

import { User } from "../types/User";

type Likes = {
  num_likes: number;
  has_user_liked: boolean;
}

type Commend = {
  id: string | number;
  receiver: User;
  giver: User;
  body: string;
  likes: Likes;
  date_created: string | Date;
}

interface CommendWallProps {
  userInfo: User;
}

const CommendWall = ({ userInfo }: CommendWallProps) => {
  const [newCommend, setNewCommend] = useState("");
  const [commends, setCommends] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [loading, setLoading] = useState(false);
  const [likeMap, setLikeMap] = useState(new Map());
  const [hasLikedMap, setHasLikedMap] = useState(new Map());
  const {user} = useContext(AuthContext);
  const [lockCommendWall, setLockCommendWall] = useState(false); 

  const updateLikeMap = (k: number, v: number) => {
    setLikeMap(new Map(likeMap.set(k, v)));
  }
  const updateHasLikedMap = (k: number, v: boolean) => {
    setHasLikedMap(new Map(hasLikedMap.set(k, v)));
  }

  const handleChangeCommend = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewCommend(e.target.value);
  }

  const formatCommendBody = (body: string) => {
    const urlRegex = /https?:\/\/[^\s]+/g;
  
    const bodyNodes = body.split(urlRegex);
  
    const urls = [...body.matchAll(urlRegex)];
  
    return (
      <>
        {bodyNodes.map((chunk, index) => (
          <React.Fragment key={index}>
            {chunk}
            {index < urls.length && (
              <a
                href={urls[index][0]}
                className="text-[#0FEFFD] underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {urls[index][0]}
              </a>
            )}
          </React.Fragment>
        ))}
      </>
    );
  };
  

  const handleCreateCommend = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (lockCommendWall) {
      return;
    } else {
      setLockCommendWall(true); 
    }
    e.preventDefault();
    setLoading(true);
    axios.post("/users/commends/", {
      giver: user?.user_id,
      body: newCommend,
    }, getAuthHeader())
    .then((response) => {
      setTrigger(!trigger);
      setNewCommend("");
      setLoading(false);
      setLockCommendWall(false); 
    });
  }

  const handleLikeCommend = (commendId: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    axios.patch("/users/commends/", {
      commend_id: commendId,
    }, getAuthHeader())
    .then((response) => {
      const oldNumLikes = likeMap.get(commendId) || 0;
      if (response.data["new_like_status"]) {
        updateLikeMap(commendId, oldNumLikes+1);
        updateHasLikedMap(commendId, true);
      } else {
        updateLikeMap(commendId, oldNumLikes-1);
        updateHasLikedMap(commendId, false);
      }
    });
  }

  useEffect(() => {
    const username = extractUsername()
    axios.get(`/users/commends/?username=${username}`, getAuthHeader())
    .then((response) => {
      const initialLikeMap = new Map();
      const initialHasLikedMap = new Map();
      response.data.forEach((commend: Commend) => {
        initialLikeMap.set(commend.id, commend.likes?.num_likes);
        initialHasLikedMap.set(commend.id, commend.likes?.has_user_liked);
      });
      setLikeMap(initialLikeMap);
      setHasLikedMap(initialHasLikedMap);
      setCommends(response.data);
    })
  }, [trigger]);

  return (
    <div className="flex flex-col gap-4">
      <div className="text-[20px] text-white font-poppins font-medium">
        Commend Wall
      </div>
      <div className="flex flex-col gap-4 bg-black rounded-3xl p-6">
        <div className="flex flex-col gap-2">
          <textarea
            className="text-[14px] font-medium bg-[#2A2A2A] resize-none border-[#D7FBFF] border-2 rounded-3xl font-poppins text-white w-full pt-4 pl-4"
            placeholder="What's your favorite thing about this person?"
            onChange={handleChangeCommend}
            value={newCommend}
            rows={2}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); 
                handleCreateCommend(e); 
              }
            }}      

          />
          <div className="relative ml-auto">
            {(loading) && <span className="absolute animate-spin h-8 top-0 right-32">
              <IconContext.Provider value={{ color: '#FFF' }}>
                <AiOutlineLoading size={30} />
              </IconContext.Provider>
            </span>}
            <Button
              size="sm"
              color="gradient"
              style={{
                "padding": "4px 12px 4px 12px",
                "fontSize": "15px",
              }}
              className="ml-auto"
              onClick={handleCreateCommend}
            >
              Post on wall
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 px-4 overflow-y-scroll max-h-60">
          {(commends).map((commend: Commend) => {
            return (
              <div className="flex flex-row gap-4 relative shadow-md pb-1 border-b-[1px] border-[#A2ECE4]/30">
                <div className="h-10 w-10 flex-shrink-0 rounded-full overflow-hidden">
                  <img src={commend?.giver?.profile_pic} alt="" className="w-full h-full object-cover" />
                </div>
                <div className="flex flex-col gap-1 flex-1 min-w-0">
                  <span className="flex flex-row gap-4 items-center">
                    <div className="text-[17px] text-white font-poppins font-bold hover:cursor-pointer truncate"
                      onClick={(e) => {
                        e.preventDefault();
                        if (commend?.giver?.username != null)
                          window.location.href = `/profile/${commend?.giver?.username}`;
                      }}
                    >
                      {commend?.giver?.username}
                    </div>
                    <div className="font-poppins text-[13px] text-[#C9C9C9] font-extralight whitespace-nowrap">
                      {timeAgo(commend?.date_created)}
                    </div>
                  </span>
                  <div className="font-poppins text-[15px] text-[#C9C9C9] break-words whitespace-pre-wrap">
                    {formatCommendBody(commend?.body)}
                  </div>
                </div>
                <div className="relative flex-shrink-0">
                  {(userInfo?.id === user?.user_id || user?.user_id === commend?.giver?.id) && <CommendToolTip
                    commend={commend}
                    trigger={trigger}
                    setTrigger={setTrigger}
                  />}
                  <span className="absolute bottom-0 right-0 flex flex-row gap-1 items-center z-0">
                    <span className="ml-1 text-[#F8F8F8] z-0">
                      {likeMap.get(commend?.id) || 0}
                    </span>
                    <IconContext.Provider value={{ "color": "#F8F8F8" }}>
                      <button onClick={handleLikeCommend(Number(commend?.id))}>
                        {hasLikedMap.get(commend?.id) ? <IoIosHeart size={20} /> : <IoIosHeartEmpty size={20} />}
                      </button>
                    </IconContext.Provider>
                  </span>
                </div>
              </div>
            );
          })}
          {(commends?.length === 0) && 
          <div className="text-[15px] font-poppins text-white">
            Be the first to post!
          </div>}
        </div>
      </div>
    </div>
  )
}

export default CommendWall;