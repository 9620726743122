import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";

import EditPostModal from "./EditPostModal";
import Button from "./Button";
import '../styles/gradiantBox.css'
import { 
  getAuthHeader,
  extractUsername
} from "../utils";

import InfiniteScroll from "react-infinite-scroll-component";
import PostContent from "./PostContent";

import AuthContext from "./AuthProvider";
import { User } from "../types/User";
import { Post } from "../types/Post";
import { Game } from "../types/Game";

import { useSearchParams } from 'react-router-dom';
import { DropdownContainer, DropdownButton, DropdownMenu, DropdownItem } from "./Dropdown";
import { IoIosArrowDown } from "react-icons/io";

interface SessionListProps {
  trigger: boolean;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGame: Game | null;
  handleOpenPostModal: React.MouseEventHandler<HTMLSpanElement>;
  setSelectedGame: React.Dispatch<React.SetStateAction<Game | null>>;
  userInfo: User;
  hasMore: boolean;
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
}


const SessionList = ({ trigger, setTrigger, selectedGame, handleOpenPostModal,
  setSelectedGame, userInfo, hasMore, setHasMore, pageNum, setPageNum }: SessionListProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showEdit, setShowEdit] = useState(false);
  const [currPost, setCurrPost] = useState<Post | null>(null);
  const [currUserInfo, setCurrUserInfo] = useState<User>({
    id: 0,
    username: "",
    bio: "",
    steam_id: "",
    profile_pic: "",
    user_timezone: "",
    discord_id: 0,
    discord_username: "",
    steam_id64: "",
    is_superuser: false,
    vibes: 0,
    is_duel_winner: false,
  });
  const [mentions, setMentions] = useState([]);
  const [postView, setPostView] = useState((searchParams.get("view") === "pinned" ? "pinned" : "all")); // "all", "pinned"
  const [showPostDrop, setShowPostDrop] = useState(false);
  // Post pagination
  const [posts, setPosts] = useState<JSX.Element[]>([]);
  const {user} = useContext(AuthContext);
  const scrollTarget = (window.screen.width < 600 ? null : "sessionDiv");

  useEffect(() => {
    if (user != null) {
      axios.get(`/users/${user.user_id}`, getAuthHeader())
      .then((response) => {
        setCurrUserInfo(response.data);
      });
      axios.get("/users", getAuthHeader())
      .then((response) => {
        setMentions(response.data.map((user: User, idx: number) => {
          return {
            "id": idx,
            "display": user?.username
          }
        }));
      })
    }
  }, []);

  useEffect(() => {
    // Reset values in case they are stale
    setPosts([]);
    setPageNum(0);
    const username = extractUsername();
    const url = (selectedGame != null) ? `/posts/?page=0&game=${selectedGame?.id}&view=${postView}&username=${username}` : `/posts/?page=0&view=${postView}&username=${username}`;
    axios.get(url, getAuthHeader())
    .then((response) => {
      let morePosts: JSX.Element[] = [];
      for (let i = 0; i < response.data["posts"]?.length; i++) {
        let post = (
          <PostContent
            post={response.data["posts"][i]}
            mentions={mentions}
            handleOpenEditModal={handleOpenEditModal}
            currUserInfo={currUserInfo}
            trigger={trigger}
            setTrigger={setTrigger}
            user={user}
          />
        );
        morePosts.push(post);
      }

      if ("next" in response.data) {
        setPageNum(Number(response.data["next"]));
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      setPosts(morePosts);
    });
  }, [trigger, selectedGame, user, currUserInfo, postView]);

  const handleFetchPosts = () => {
    const username = extractUsername();
    const url = (selectedGame != null) ? `/posts/?page=${pageNum}&game=${selectedGame?.id}&view=${postView}&username=${username}` : `/posts/?page=${pageNum}&view=${postView}&username=${username}`
    axios.get(url, getAuthHeader())
    .then((response) => {
      // Map raw posts to components
      let morePosts = [];
      for (let i = 0; i < response.data["posts"]?.length; i++) {
        let post = (
          <PostContent
            post={response.data["posts"][i]}
            mentions={mentions}
            handleOpenEditModal={handleOpenEditModal}
            currUserInfo={currUserInfo}
            trigger={trigger}
            setTrigger={setTrigger}
            user={user}
          />
        );
        morePosts.push(post);
      }

      // Extend posts array
      setPosts(posts.concat(morePosts));

      // Handle more posts case
      if (!("next" in response.data)) {
        setHasMore(false);
      } else {
        setPageNum(Number(response.data["next"]));
        setHasMore(true);
      }
    })
  }

  const handleOpenEditModal = (post: Post) => (e: any) => {
    e.preventDefault();
    setCurrPost(post);
    setShowEdit(true);
  }

  const handleSaveDotaMatchesEndpoint = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    axios.get("/dota_matches/save_dota_match", getAuthHeader())
  }

  const handleTogglePostViewDropdown = () => setShowPostDrop(!showPostDrop);

  return (
    <div id="sessionDiv" className="flex flex-col overflow-y-auto">
      <span className="flex flex-row items-end mb-2 sm:mb-4">
        <span className="text-xl sm:text-[24px] text-white flex items-center gap-4">
          <span className={"hover:cursor-pointer " + (postView !== "pinned" ? "text-white" : "text-[#85B6FF]")}
            onClick={() => {
              setPostView("all");
              setPageNum(0);
            }}
          >
            Sessions
          </span>
          <span className={"hover:cursor-pointer " + (postView === "pinned" ? "text-white" : "text-[#85B6FF]")}
            onClick={() => {
              setPostView("pinned");
              setPageNum(0);
            }}
          >
            Pinned
          </span>
          <span className="text-[#85B6FF] text-[10px] sm:text-sm ml-4 hover:cursor-pointer hover:underline"
            onClick={() => {
              setSelectedGame(null);
              setPageNum(0);
            }}
          >
            view all
          </span>
        </span>
        <div className="flex flex-row gap-2 ml-auto items-center">
          {(user?.user_id === userInfo?.id) &&
          <Button color="gradient"
            size="sm"
            onClick={(e) => {
              handleSaveDotaMatchesEndpoint(e);
              handleOpenPostModal(e);
            }}
          >
            Create a Post
          </Button>}
        </div>
      </span>
      <InfiniteScroll
        dataLength={posts?.length}
        next={() => handleFetchPosts()}
        hasMore={hasMore}
        loader={<h4 className="text-[13px] sm:text-lg italic text-white">Loading...</h4>}
        className="pl-2 sm:pl-8"
        scrollThreshold="15px"
        scrollableTarget={scrollTarget}
      >
        <ol className="relative sm:mb-48">
          {posts.map((item: JSX.Element) => item)}
        </ol>
      </InfiniteScroll>
      {(currPost) && 
      <EditPostModal
        show={showEdit}
        onHide={() => { setShowEdit(false) }}
        trigger={trigger}
        setTrigger={setTrigger}
        postID={currPost?.id}
        isFeat={(currPost?.post_type === "feat")}
        mentions={mentions}
      />}
    </div>
  )
}


export default SessionList;