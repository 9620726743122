import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import AuthContext from "./AuthProvider";
import { getAuthHeader } from "../utils";

// Import your icons
import hardSupportIcon from "../assets/hard_support.png";
import softSupportIcon from "../assets/soft_support.png";
import offLaneIcon from "../assets/off_lane.png";
import midLaneIcon from "../assets/mid_lane.png";
import safeLaneIcon from "../assets/safe_lane.png";
import sleepingMascot from "../assets/sleeping_mascot.webp"


const Challenges = () => {
  const { user } = useContext(AuthContext);
  const [challenges, setChallenges] = useState<any[]>([]);
  const isMobile = window.screen.width < 600;

  useEffect(() => {
    axios
      .post("/challenge/get_challenges/", getAuthHeader())
      .then((response) => {
        if (!response.data || Object.keys(response.data).length === 0) {
          return;
        }
        setChallenges(response.data);
      });
  }, []);

  const handleAccept = (challengeId : number) => {
    const payload = { challenge: challengeId };
    axios.post("/challenge/accept/", payload, getAuthHeader()).then(() => {
      setChallenges(challenges.map(challenge => {
        if (challenge.id === challengeId) {
          return { ...challenge, accepted: true };
        }
        return challenge;
      }));
    });
  };

  const getPositionIcon = (position : number) => {
    switch (position) {
      case 1:
        return safeLaneIcon;
      case 2:
        return midLaneIcon;
      case 3:
        return offLaneIcon;
      case 4:
        return softSupportIcon;
      case 5:
        return hardSupportIcon;
      default:
        return "";
    }
  };

  const renderChallenge = (challenge) => {
    if (!challenge.accepted) {
      return (
        <div className="min-w-[260px] w-[260px] sm:w-full bg-black text-white rounded-xl sm:rounded-3xl p-3 sm:p-6 border-2 border-purple-500 text-center">
          <div className="text-xs sm:text-sm font-light mb-1 sm:mb-2 text-gray-400 flex justify-center items-center space-x-1 sm:space-x-2">
            <span>{challenge.position_title}</span>
            {challenge.positions.map((pos : number, index : number) => (
              <img
                key={index}
                src={getPositionIcon(pos)}
                alt={`Position ${pos}`}
                className="w-4 h-4 sm:w-6 sm:h-6"
              />
            ))}
          </div>
          <div className="text-sm sm:text-lg font-bold mb-1 sm:mb-2">{challenge.title}</div>
          <div className="text-xs sm:text-sm font-light mb-3 sm:mb-6">{challenge.subtitle}</div>
          <button
            onClick={() => handleAccept(challenge.id)}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-1 sm:py-2 px-4 sm:px-6 rounded-lg text-sm sm:text-base"
          >
            {challenge.accepted ? "Challenge Accepted" : "Accept Challenge"}
          </button>
        </div>
      );
    }

    const completedMatches = challenge.info.matches.filter((match) => match.complete).length;
    const progressPercentage = Math.min((completedMatches / challenge.completion_number) * 100, 100);

    return (
      <div className="min-w-[260px] w-[260px] sm:w-full bg-black text-white rounded-xl sm:rounded-3xl p-2 sm:p-4 border-2 border-purple-500">
        <div>
          <div className="text-xs sm:text-sm font-light mb-1 sm:mb-2 text-gray-400 flex items-center space-x-1 sm:space-x-2">
            <span>{challenge.position_title}</span>
            {challenge.positions.map((pos, index) => (
              <img
                key={index}
                src={getPositionIcon(pos)}
                alt={`Position ${pos}`}
                className="w-4 h-4 sm:w-6 sm:h-6"
              />
            ))}
          </div>
          <div className="flex items-center justify-between mb-1 sm:mb-2">
            <div className="text-sm sm:text-lg font-bold">{challenge.title}</div>
            {progressPercentage === 100 && (
              <div className="flex items-center justify-center bg-green-500 rounded-full px-2 py-0.5">
                <svg className="w-3 h-3 sm:w-4 sm:h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                </svg>
              </div>
            )}
          </div>
          <div className="text-xs sm:text-sm font-light mb-2 sm:mb-4">{challenge.subtitle}</div>
        </div>

        <div className="mb-2 sm:mb-4">
          <div className="relative w-full h-3 sm:h-4 bg-gray-300 rounded-lg">
            <div
              className="absolute top-0 left-0 h-full bg-purple-500 rounded-lg"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>
          <div className="mt-1 sm:mt-2 text-xs sm:text-sm text-right">
            {completedMatches}/{challenge.completion_number} games
          </div>
        </div>

        <div className="text-xs sm:text-sm text-gray-400 mb-2 sm:mb-4 text-center">
          {challenge.start_time_formatted} to {challenge.end_time_formatted}
        </div>

        <div className="flex justify-between mb-2 sm:mb-4">
          <div className="text-xs sm:text-sm text-gray-400">
            Success Rate:{" "}
            <span className="font-bold text-white">
              {(challenge.info.success_rate * 100).toFixed(2)}%
            </span>
          </div>
          <div className="text-xs sm:text-sm text-gray-400">
            Avg: <span className="font-bold text-white">{challenge.info.average_score.toFixed(2)}</span>
          </div>
        </div>

        <div className="h-32 sm:h-64 overflow-y-scroll">
          <table className="w-full border-collapse text-xs sm:text-sm">
            <thead>
              <tr className="border-b border-gray-600">
                <th className="py-1 sm:py-2 px-2 sm:px-4 text-center">Done</th>
                <th className="py-1 sm:py-2 px-2 sm:px-4 text-center">Hero</th>
                <th className="py-1 sm:py-2 px-2 sm:px-4 text-center">Pos</th>
                <th className="py-1 sm:py-2 px-2 sm:px-4 text-right">{challenge.stat_title}</th>
              </tr>
            </thead>
            <tbody>
              {challenge.info.matches.map((match, index) => {
                const utmSource = "HiLo";
                const redirectUrl = `https://stratz.com/matches/${match.id}/?utm=${utmSource}`;
                const handleClick = () => {
                  if (match.post_id) {
                    window.location.href = `/posts/${match.post_id}`;
                  } else {
                    window.open(redirectUrl, "_blank");
                  }
                };
                return (
                  <tr key={index} className="border-b border-gray-600 text-center align-middle">
                    <td className="py-1 sm:py-2 px-2 sm:px-4 text-left">
                      {match.complete ? (
                        <div className="flex justify-center items-center w-5 h-5 sm:w-6 sm:h-6 rounded-full bg-green-500 mx-auto">
                          <svg className="w-3 h-3 sm:w-4 sm:h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center w-5 h-5 sm:w-6 sm:h-6 rounded-full bg-red-500 mx-auto">
                          <svg className="w-3 h-3 sm:w-4 sm:h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </div>
                      )}
                    </td>
                    <td className="py-1 sm:py-2 px-2 sm:px-4 text-left">
                      <div onClick={handleClick} className="cursor-pointer">
                        <img
                          src={match.hero}
                          alt="Hero"
                          className="w-6 h-6 sm:w-10 sm:h-10 object-cover mx-auto"
                        />
                      </div>
                    </td>
                    <td className="py-1 sm:py-2 px-2 sm:px-4 text-left">
                      <img
                        src={getPositionIcon(match.position)}
                        alt="Position Icon"
                        className="w-5 h-5 sm:w-8 sm:h-8 mx-auto"
                      />
                    </td>
                    <td className="py-1 sm:py-2 px-2 sm:px-4 text-right">
                      {match.score !== undefined && match.score !== null
                        ? match.score
                        : "Not Parsed"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <>
      {challenges.length > 0 ? (
        <div className={isMobile && challenges.length > 1 ? "w-full overflow-x-auto hide-scrollbar" : ""}>
          <div 
            className={`flex ${
              isMobile && challenges.length > 1 ? 'flex-row' : 'flex-col'
            } gap-4`}
            style={isMobile && challenges.length > 1 ? { paddingBottom: '8px' } : {}}
          >
            {challenges.map((challenge) => (
              <div 
                key={challenge.id} 
                className={
                  isMobile && challenges.length > 1 
                    ? "flex-shrink-0" 
                    : "w-full"
                }
              >
                {renderChallenge(challenge)}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center text-center">
          <img 
            src={sleepingMascot}
            alt="Sleeping mascot" 
            className="w-32 h-32 mb-4"
          />
          <p className="text-gray-400 text-sm">
            zzz... no challenges for now, go touch some grass!
          </p>
        </div>
      )}
    </>
  );
};

// Add this CSS to your global styles or component style
// .hide-scrollbar::-webkit-scrollbar {
//   display: none;
// }
// .hide-scrollbar {
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
// }

export default Challenges;