import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { DropdownContainer, DropdownButton, DropdownMenu, DropdownItem } from "./Dropdown";
import { CardTextarea } from "./PostCard";
import Modal from "./Modal";

import { IconContext } from 'react-icons/lib';
import { AiOutlinePicture } from "react-icons/ai";
import { IoVideocamOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlineLoading } from "react-icons/ai";
import { TbEyeFilled } from "react-icons/tb";
import AuthContext from "./AuthProvider";
import { getAuthHeader, mapToEmoji } from "../utils";

import MediaCarousel from "./MediaCarousel";
import MatchesList from "./MatchesList";
import { Mention, MentionsInput } from "react-mentions";
import { emojis, emojiIDs } from "../emojis";

import "../styles/PostMentions.css"

import { User } from "../types/User";
import { Game } from "../types/Game";
import { SuggestionDataItem } from "../types/Mentions";
import { MentionsInputChangeEvent } from "../types/Mentions";
import { DotaMatchPreview } from "../types/Dota";
import { BaseHeaderType } from "../types/Auth";
import { TwitchInfo, YoutubeInfo } from "../types/Integrations";

const MentionsStyle = {
  backgroundColor: '#41487a',
  borderRadius: '5px',
}
const EmojisStyle = {
  backgroundColor: 'transparent',
}

interface PostModalProps {
  show: boolean;
  onHide: () => void;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  trigger: boolean;
  userInfo: User;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  mentions: SuggestionDataItem[];
  games: Array<Game>;
  selectedGame: Game;
  setSelectedGame: React.Dispatch<React.SetStateAction<Game>>;
}

const PostModal = ({ show, onHide, setTrigger, trigger, userInfo, setPageNum,
  mentions,
  games,
  selectedGame, setSelectedGame
}: PostModalProps) => {
  const [showDrop, setShowDrop] = useState(false);
  const [postInfo, setPostInfo] = useState({
    "title": "",
    "metric_1": "",
    "value_1": "",
    "metric_2": "",
    "value_2": "",
    "metric_3": "",
    "value_3": "",
    "body": "",
    "twitch_clip_id": "",
    "youtube_video_id": "",
  });
  const [selectMatches, setSelectMatches] = useState(false);
  const [matchInfo, setMatchInfo] = useState<DotaMatchPreview[]>([]);
  const [twitchClipInfo, setTwitchClipInfo] = useState<TwitchInfo>({
    id: "",
    url: "",
    embed_url: "",
    broadcaster_id: "",
    broadcaster_name: "",
    creator_id: "",
    creator_name: "",
    video_id: "",
    game_id: "",
    language: "",
    title: "",
    view_count: 0,
    created_at: "",
    thumbnail_url: "",
    duration: 0,
    vod_offset: 0
  });
  const [youtubeInfo, setYoutubeInfo] = useState<YoutubeInfo>({
    video_title: "",
    channel_title: "",
    thumbnail_url: "",
  });
  const [postCreating, setPostCreating] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const {user} = useContext(AuthContext);
  const dropRef = useRef<HTMLDivElement>(null);
  const [videoUUID, setVideoUUID] = useState("");
  const [isMediaVideo, setIsMediaVideo] = useState(false);
  const [isVideoPreview, setIsVideoPreview] = useState(false);

  // Photo uploading
  const [files, setFiles] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  const handlePostInfo = (field: string) => (e: MentionsInputChangeEvent | React.ChangeEvent<HTMLTextAreaElement>) => {
    if (field === "title" && e.target.value.length > 0) {
      setTitleErr(false);
    }
    setPostInfo({
      ...postInfo,
      [field]: e.target.value,
    });
  }

  const toggleDropdown = () => setShowDrop(!showDrop);

  const handleCloseDropdown = (e: MouseEvent) => {
    if (showDrop && !dropRef.current?.contains(e.target as Node)) {
      setShowDrop(false);
    }
  }
  document.addEventListener('mousedown', handleCloseDropdown)

  const isEmptyUrl = (url: string) => {
    return (url === `""` || url.length == 0);
  }

  const handleSelectDropdown = (game: Game) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setSelectedGame(game);
    setPostInfo({
      ...postInfo,
      "metric_1": game?.preset_fields[0] || "",
      "metric_2": game?.preset_fields[1] || "",
      "metric_3": game?.preset_fields[2] || "",
    });
    setShowDrop(false);
  }

  const handleCreatePost = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (postInfo["title"].length === 0) {
      setTitleErr(true);
      return;
    }

    setPostCreating(true);

    // Only use user-specified if summaryInfo is empty
    let metadata: Record<string, any> = {};
    let matches: number[] = [];
    let post_type = "";

    if (!selectMatches) {
      if (postInfo["metric_1"].length > 0 && postInfo["value_1"].length > 0) {
        metadata["metric_1"] = {
          "name": postInfo["metric_1"],
          "value": {
            "custom": postInfo["value_1"]
          }
        }
      }
      if (postInfo["metric_2"].length > 0 && postInfo["value_2"].length > 0) {
        metadata["metric_2"] = {
          "name": postInfo["metric_2"],
          "value": {
            "custom": postInfo["value_2"]
          }
        }
      }
      if (postInfo["metric_3"].length > 0 && postInfo["value_3"].length > 0) {
        metadata["metric_3"] = {
          "name": postInfo["metric_3"],
          "value": {
            "custom": postInfo["value_3"]
          }
        }
      }
      post_type = "generic";
    } else {
      // map matchInfo to just the dotamatch IDs
      matches = matchInfo.map((match) => Number(match.id));
      post_type = "session";
    }

    const formData = new FormData();

    if (!isMediaVideo) {
      // direct upload only for static media (pictures, etc)
      files.forEach((file) => {
        formData.append('user_media', file);
      });
    } else {
      const videoData = new FormData();
      videoData.append('uuid', videoUUID);
      videoData.append('file', files[0]);
      videoData.append('url', `${process.env.REACT_APP_API_URL}`);

      let baseVideoHeader: BaseHeaderType = getAuthHeader();
      baseVideoHeader["headers"]["Content-Type"] = "multipart/form-data";
      axios.post(`${process.env.REACT_APP_VIDEO_API_URL}/video/`, videoData, baseVideoHeader);
      formData.append('video_uuid', videoUUID);
    }

    let cleanedBody = postInfo["body"].replace(/\[(\S*?)\s(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)\]/gu, '$2');
    cleanedBody = cleanedBody.replace(/\(\d+\)/g, "");
    let cleanedTitle = postInfo["title"].replace(/\[(\S*?)\s(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)\]/gu, '$2');
    cleanedTitle = cleanedTitle.replace(/\(\d+\)/g, "");

    formData.append('body', cleanedBody);
    formData.append('title', cleanedTitle);

    console.log("new metadata:", metadata);

    if(!(selectedGame == null || selectedGame == undefined)){
      formData.append('game', String(selectedGame.id));
    }
    formData.append('metadata', JSON.stringify(metadata));
    formData.append('twitch_clip_id', postInfo["twitch_clip_id"]);
    formData.append('youtube_video_id', postInfo["youtube_video_id"]);
    formData.append('post_type', post_type);
    formData.append('matches', JSON.stringify(matches));

    let baseHeader: BaseHeaderType = getAuthHeader();
    baseHeader["headers"]["Content-Type"] = "multipart/form-data";
    axios.post("/posts/", formData, baseHeader)
    .then((response) => {
      setPageNum(0);
      setPostCreating(false);
      setTrigger(!trigger);
      setPostInfo({
        "title": "",
        "metric_1": "",
        "value_1": "",
        "metric_2": "",
        "value_2": "",
        "metric_3": "",
        "value_3": "",
        "body": "",
        "twitch_clip_id": "",
        "youtube_video_id": "",
      });
      onHide();                 // make sure we reset after a post is made, just like when onHide is called
      setFiles([]);
      setPreviewUrls([]);
      setSelectMatches(false);
      setMatchInfo([]);
      setIsVideoPreview(false);
    });
  }

  const handleUploadMedia = (isVideo: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setIsMediaVideo(isVideo);
      if (isVideo) {
        setVideoUUID(uuidv4());
        setIsVideoPreview(true);
      }

      const selectedFiles = Array.from(e.target.files) as File[];
      setFiles(selectedFiles);

      // Generate preview URLs
      const urls = selectedFiles.map(file => URL.createObjectURL(file));
      setPreviewUrls(urls);
    }
  }

  const handlePasteBody = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    setPostInfo({
      ...postInfo,
      "body": "",
    });

    // Check if this is a twitch clip
    const pastedText = e.clipboardData.getData('Text');
    const twitchRegex = /https:\/\/(?:www\.)?twitch\.tv\/[^\/]+\/clip\/([^?]+)|https:\/\/clips\.twitch\.tv\/([^?]+)/;
    const twitchMatch = pastedText.match(twitchRegex);

    // Check if this is a youtube video too
    const youtubeRegex = /(?:https:\/\/(?:www\.)?youtube\.com\/watch\?v=([^&\s]+)|https:\/\/youtu\.be\/([^?&\s]+))/;
    const youtubeMatch = pastedText.match(youtubeRegex);

    // https://www.youtube.com/watch?v=EDj9B9nGnLA
    // https://youtu.be/EDj9B9nGnLA?si=cMV-CThG72htI4Gw

    if (twitchMatch && postInfo["youtube_video_id"]?.length === 0) {
      axios.get(`/posts/twitch?clip_id=${twitchMatch[1] || twitchMatch[2]}`, getAuthHeader())
      .then((response) => {
        setTwitchClipInfo(response.data);
        setPostInfo({
          ...postInfo,
          "twitch_clip_id": twitchMatch[1] || twitchMatch[2],
        });
      })
    } else if (youtubeMatch && postInfo["twitch_clip_id"]?.length === 0) {
      axios.get(`/posts/youtube?video_id=${youtubeMatch[1] || youtubeMatch[2]}`, getAuthHeader())
      .then((response) => {
        setYoutubeInfo(response.data);
        setPostInfo({
          ...postInfo,
          "youtube_video_id": youtubeMatch[1] || youtubeMatch[2],
        });
      })
    }
    
    const newImgs: File[] = [];
    const newUrls: string[] = [];

    // Check if this is an image
    for (let item of e.clipboardData.items) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        if (file != null) {
          newImgs.push(file);
          newUrls.push(URL.createObjectURL(file));
        }
      }
    }

    setFiles((prevImgs) => [...prevImgs, ...newImgs]);
    setPreviewUrls((prevUrls) => [...prevUrls, ...newUrls]);
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setFiles([]);
        setPreviewUrls([]);
        setSelectMatches(false);
        setTwitchClipInfo({
          id: "",
          url: "",
          embed_url: "",
          broadcaster_id: "",
          broadcaster_name: "",
          creator_id: "",
          creator_name: "",
          video_id: "",
          game_id: "",
          language: "",
          title: "",
          view_count: 0,
          created_at: "",
          thumbnail_url: "",
          duration: 0,
          vod_offset: 0
        });
        setYoutubeInfo({
          video_title: "",
          channel_title: "",
          thumbnail_url: "",
        });
        setPostInfo({
          "title": "",
          "metric_1": "",
          "value_1": "",
          "metric_2": "",
          "value_2": "",
          "metric_3": "",
          "value_3": "",
          "body": "",
          "twitch_clip_id": "",
          "youtube_video_id": "",
        });
        setMatchInfo([]);
        setIsVideoPreview(false);
        onHide();
      }}
      size="lg"
      title={
      <div className="flex flex-col gap-4">
        <span>
          <h1 className="text-white sm:text-xl font-semibold">
            Create post
          </h1>
        </span>
        {(selectMatches) &&
        <span className="flex flex-col gap-2">
          <p className="sm:text-base text-white">Title</p>
          <div>
            {(titleErr) && 
            <p className="text-red-500 text-[12px]">* Title must not be empty</p>
            }
            <MentionsInput
              value={postInfo["title"]}
              placeholder="Beat the boss"
              onChange={handlePostInfo("title")}
              className="postTitleMentionWrapper"
              style={{
                "fontSize": "22px",
                "input": {
                  overflow: "scroll",
                  padding: "10px 12px",
                  scrollbarWidth: "none"
                },
                "highlighter": {
                  overflow: "hidden"
                },
              }}
              singleLine={false}
            >
              <Mention
                trigger="@"
                data={mentions}
                displayTransform={(id, display) => {
                  if (!emojiIDs.includes(id)) {
                    return `@${display}`;
                  }
                  return display;
                }}
                style={MentionsStyle}
                markup="@[__display__](__id__)"
              />
              <Mention
                trigger=":"
                data={emojis}
                displayTransform={(id, display) => {
                  if (emojiIDs.includes(id)) {
                    return mapToEmoji(display);
                  }
                  return display;
                }}
                style={EmojisStyle}
                appendSpaceOnAdd={true}
                markup="[__display__]"
              />
            </MentionsInput>
          </div>
        </span>
        }
        {(selectMatches)
          && 
          <MatchesList
            game={selectedGame} 
            matchInfo={matchInfo} 
            setMatchInfo={setMatchInfo}
        />}
        {(!selectMatches) && 
        <span className="flex flex-row gap-2">
          <div className="h-12 w-12 rounded-full overflow-hidden">
            <img src={userInfo?.profile_pic} alt="" className="w-full h-full object-cover" />
          </div>
          <div className="flex flex-col gap-1">
            {(games.length > 0) &&
            <div className="flex flex-col gap-2">
              <DropdownContainer style={{marginBottom: "5px"}} ref={dropRef}>
                <DropdownButton onClick={toggleDropdown}>
                  <span className="flex flex-row items-center gap-2">
                    {selectedGame != null && !isEmptyUrl(selectedGame?.icon_url) && 
                      <img src={selectedGame?.icon_url} className="h-4" />
                    }
                    <div className="pr-1 text-[12px]">{selectedGame?.title}</div>
                    <IoIosArrowDown />
                  </span>
                </DropdownButton>
                <DropdownMenu show={showDrop}>
                  {(games).map((game: Game) => {
                    return (
                      <DropdownItem onClick={handleSelectDropdown(game)}
                        className="hover:cursor-pointer"
                      >
                        {game.title}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </DropdownContainer>
              {(selectedGame?.can_generate) && <button
                className="bg-lavendar-mint rounded-full text-black px-3 py-1 text-[11px]"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectMatches(true);
                  setMatchInfo([]);
                }}
              >
                Select matches
              </button>}
            </div>}
          </div>
        </span>}
      </div>
      }
      footer={
        <div className="grid grid-cols-2 w-full">
          <div className="flex gap-8 items-center col-span-1">
            <label htmlFor="upload-photo"
              className="hover:cursor-pointer"
            >
              <IconContext.Provider
                value={{ color: '#85B6FF' }}
              >
                <AiOutlinePicture size={24}/>
              </IconContext.Provider>
            </label>
            <input type="file" multiple name="pic" id="upload-photo" style={{ opacity: 0, position: "absolute", zIndex: "0", width: "0" }}
              accept="image/*"
              onChange={handleUploadMedia(false)}
            />
            <label htmlFor="upload-video"
              className="hover:cursor-pointer"
            >
              <IconContext.Provider
                value={{ color: '#85B6FF' }}
              >
                <IoVideocamOutline size={24} />
              </IconContext.Provider>
            </label>
            <input type="file" name="vid" id="upload-video" style={{ opacity: 0, position: "absolute", zIndex: "0", width: "0"}}
              accept="video/mp4,video/x-m4v,video/*"
              onChange={handleUploadMedia(true)}
            />
          </div>
          <div className="flex col-span-1 pl-auto relative">
            {(postCreating) && <span className="absolute animate-spin h-10 bottom-0 right-20">
              <IconContext.Provider
                value={{ color: '#FFF'}}
              >
                <AiOutlineLoading size={40} />
              </IconContext.Provider>
            </span>}
            <button
              onClick={handleCreatePost}
              className="ml-auto"
              style={{
                borderRadius: "30px",
                background: "#85B6FF", // Gray when disabled
                color: "#FFFFFF",
                padding: "6px 16px",
                fontSize: "20px",
                border: "none",
                fontFamily: "Poppins",
                cursor: "pointer", // Change cursor when disabled
                zIndex: "5",
              }}
            >
              Post
            </button>
          </div>
        </div>
      }
    >
      <div className="flex flex-col sm:gap-2">
        {(!selectMatches) && <p className="sm:text-base text-white">Title</p>}
        {(!selectMatches && titleErr) && 
          <p className="text-red-500 text-[12px]">* Title must not be empty</p>
        }
        {(!selectMatches) && <MentionsInput
          value={postInfo["title"]}
          placeholder="Beat the boss"
          onChange={handlePostInfo("title")}
          className="postTitleMentionWrapper"
          style={{
            "fontSize": "22px",
            "input": {
              overflow: "scroll",
              padding: "10px 12px",
              scrollbarWidth: "none"
            },
            "highlighter": {
              overflow: "hidden"
            },
          }}
          singleLine={false}
        >
          <Mention
            trigger="@"
            data={mentions}
            displayTransform={(id, display) => {
              if (!emojiIDs.includes(id)) {
                return `@${display}`;
              }
              return display;
            }}
            style={MentionsStyle}
            markup="@[__display__](__id__)"
          />
          <Mention
            trigger=":"
            data={emojis}
            displayTransform={(id, display) => {
              if (emojiIDs.includes(id)) {
                return mapToEmoji(display);
              }
              return display;
            }}
            style={EmojisStyle}
            appendSpaceOnAdd={true}
            markup="[__display__]"
          />
        </MentionsInput>}
        {(!selectedGame?.can_generate) && <p className="sm:text-base text-white">Metrics</p>}
        {(!selectedGame?.can_generate) &&
        <div className="sm:grid sm:grid-cols-3 flex flex-col gap-2 sm:gap-0">
          <div className="flex flex-col gap-1 sm:gap-2 border-none sm:border-r-2 border-gray-500 pr-4">
            <CardTextarea
              value={postInfo["metric_1"]}
              onChange={handlePostInfo("metric_1")}
              rows={1}
              placeholder="Difficulty"
              className="col-span-1"
              style={{"fontSize": "12px"}}
            />
            <CardTextarea
              value={postInfo["value_1"]}
              onChange={handlePostInfo("value_1")}
              rows={1}
              placeholder={((selectedGame == null || Object.keys(selectedGame).length === 0) ? "Value 1" : (selectedGame?.preset_placeholders[0] || "Value 1"))}
              className="col-span-1"
              style={{"fontSize": "14px"}}
            />
          </div>
          <div className="flex flex-col gap-1 sm:gap-2 border-none sm:border-r-2 border-gray-500 sm:px-4">
            <CardTextarea
              value={postInfo["metric_2"]}
              onChange={handlePostInfo("metric_2")}
              rows={1}
              placeholder="Class"
              className="col-span-1"
              style={{"fontSize": "12px"}}
            />
            <CardTextarea
              value={postInfo["value_2"]}
              onChange={handlePostInfo("value_2")}
              rows={1}
              placeholder={((selectedGame == null || Object.keys(selectedGame).length === 0) ? "Value 2" : (selectedGame?.preset_placeholders[1] || "Value 2"))}
              className="col-span-1"
              style={{"fontSize": "14px"}}
            />
          </div>
          <div className="flex flex-col gap-1 sm:gap-2 sm:pl-4">
            <CardTextarea
              value={postInfo["metric_3"]}
              onChange={handlePostInfo("metric_3")}
              rows={1}
              placeholder="Boss"
              className="col-span-1"
              style={{"fontSize": "12px"}}
            />
            <CardTextarea
              value={postInfo["value_3"]}
              onChange={handlePostInfo("value_3")}
              rows={1}
              placeholder={((selectedGame == null || Object.keys(selectedGame).length === 0) ? "Value 3" : (selectedGame?.preset_placeholders[2] || "Value 3"))}
              className="col-span-1"
              style={{"fontSize": "14px"}}
            />
          </div>
        </div>}
        <p className="sm:text-base text-white">Caption</p>
        <MentionsInput
          value={postInfo["body"]}
          placeholder="Only took 97 tries but we here"
          onChange={handlePostInfo("body")}
          onPaste={handlePasteBody}
          className="postMentionWrapper"
          style={{
            "fontSize": "20px",
            "input": {
              overflow: "scroll",
              padding: "10px 12px",
              scrollbarWidth: "none"
            },
            "highlighter": {
              overflow: "hidden"
            },
          }}
          singleLine={false}
        >
          <Mention
            trigger="@"
            data={mentions}
            displayTransform={(id, display) => {
              if (!emojiIDs.includes(id)) {
                return `@${display}`;
              }
              return display;
            }}
            style={MentionsStyle}
            markup="@[__display__](__id__)"
          />
          <Mention
            trigger=":"
            data={emojis}
            displayTransform={(id, display) => {
              if (emojiIDs.includes(id)) {
                return mapToEmoji(display);
              }
              return display;
            }}
            style={EmojisStyle}
            appendSpaceOnAdd={true}
            markup="[__display__]"
          />
        </MentionsInput>
        {(previewUrls.length > 0) &&
        <div className="py-2">
          {(previewUrls.length > 0) &&
            <MediaCarousel slides={previewUrls} videoURL={previewUrls[0]} preview={true} videoName={(files.length > 0 ? files[0].name : "")} isVideoPreview={isVideoPreview} />
          }
        </div>}
        {(postInfo["twitch_clip_id"] && postInfo["twitch_clip_id"].length > 0) &&
        <span 
          className="flex flex-row sm:gap-4 sm:my-2 bg-black rounded-3xl sm:p-4 transition transform hover:-translate-y-2 hover:shadow-l hover:cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            window.open(twitchClipInfo["url"]);
          }}
        >
          <div>
            <img src={twitchClipInfo["thumbnail_url"]} alt="" className="sm:h-12 sm:rounded-xl"/>
          </div>
          <div className="flex flex-col">
            <p className="font-sans sm:text-[16px] text-[#9146FF]">{twitchClipInfo["title"]}</p>
            <p className="font-sans sm:text-[12px] text-white]">Streamer: {twitchClipInfo["broadcaster_name"]}</p>
          </div>
          <img src="https://cdn-icons-png.flaticon.com/512/5968/5968819.png" alt="" className="ml-auto sm:h-12" />
        </span>
        }
        {(postInfo["youtube_video_id"] && postInfo["youtube_video_id"].length > 0) &&
        <span 
          className="flex flex-row sm:gap-4 sm:my-2 bg-black rounded-3xl sm:p-4 transition transform hover:-translate-y-2 hover:shadow-l hover:cursor-pointer"
        >
          <div>
            <img src={youtubeInfo["thumbnail_url"]} alt="" className="sm:h-12 sm:rounded-xl"/>
          </div>
          <div className="flex flex-col">
            <p className="font-sans sm:text-[16px] text-[#FFFFFF]">{youtubeInfo["video_title"]}</p>
            <p className="font-sans sm:text-[12px] text-white]">Channel: {youtubeInfo["channel_title"]}</p>
          </div>
          <img src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png" alt="" className="ml-auto sm:h-12" />
        </span>
        }
      </div>
      <div style={{
        color: "#85B6FF",
        fontFamily: "Poppins",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "0.5rem"
      }}>
        <TbEyeFilled size={24} />
        <div>
          Everyone can see
        </div>
      </div>
    </Modal>
  )
}

export default PostModal;