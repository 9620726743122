import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import axios from 'axios';
import MainLayout from '../layout/MainLayout.tsx';
import WidgetList from '../components/WidgetList.tsx';
import PostModal from '../components/PostModal.tsx';
import SessionList from '../components/SessionList.tsx';
import { SingleSelectGameList } from '../components/GameList.tsx';

import { FaCircle } from "react-icons/fa";
import { IconContext } from 'react-icons/lib';

import { extractUsername, getAuthHeader } from '../utils.ts';

import { getTimezoneDisplay } from '../timezones.ts';
import { useParams } from 'react-router-dom';

import VibesIcon from "../assets/small-vibes-icon.png";
import AuthContext from '../components/AuthProvider.tsx';
import { User } from '../types/User.ts';
import { Game } from '../types/Game.ts';

import HiloCrown from '../assets/hilo-crown.webp'

const Profile = () => {
  const [games, setGames] = useState([]);
  const [show, setShow] = useState(false);
  const [postTrigger, setPostTrigger] = useState(false);
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [pageNum, setPageNum] = useState(0);
  const [userInfo, setUserInfo] = useState<User>({
    id: 0,
    username: "",
    discord_id: 0,
    discord_username: "",
    bio: "",
    steam_id: "",
    steam_id64: "",
    profile_pic: "",
    user_timezone: "",
    is_superuser: false,
    vibes: 0,
    is_duel_winner: false,
    selected_game: {
      id: "",
      title: "",
      icon_url: "",
      can_generate: false,
      igdb_id: 0,
      preset_fields: [],
      preset_placeholders: [],
    }
  });
  const [isFriend, setIsFriend] = useState("not_friend");
  const [mentions, setMentions] = useState([]);
  const [userGames, setUserGames] = useState([]);
  const [selectedUserGame, setSelectedUserGame] = useState<Game>({
    id: "",
    title: "",
    icon_url: "",
    can_generate: false,
    igdb_id: 0,
    preset_fields: [],
    preset_placeholders: [],
  });
  const [time, setTime] = useState("");
  const {user} = useContext(AuthContext);
  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const username = extractUsername();
        const gamesResponse = await axios.get(`/games/?username=${username}`, getAuthHeader());
        setGames(gamesResponse.data);

        for (let i = 0; i < gamesResponse.data?.length; i++) {
          if (gamesResponse.data[i]["title"] === "Dota 2") {
            setSelectedGame(gamesResponse.data[i]);
            break;
          }
        }
  
        const userInfoResponse = await axios.post(`/users/search/`, {
          username: params.username,
        });
        setUserInfo(userInfoResponse.data);
        
        const isFriendResponse = await axios.get(`/users/${userInfoResponse?.data['id']}/friend_list/?is_friend`, getAuthHeader());
        setIsFriend((isFriendResponse.data["friend_status"]));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (user != null) {
      axios.get("/users", getAuthHeader())
      .then((response) => {
        setMentions(response.data.map((user: User, idx: number) => {
          return {
            "id": idx,
            "display": user?.username
          }
        }));
        axios.get(`/games/?user_id=${user.user_id}`)
        .then((response) => {
          setUserGames(response.data);
          setSelectedUserGame(response.data[0]);
        })
      });
    }
  }, []);

  // Updating the time
  useEffect(() => {
    const updateTime = () => {
      setTime(getTimezoneDisplay(userInfo?.user_timezone));
    }

    const intervalID = setInterval(updateTime, 60000);
    updateTime();
    return () => clearInterval(intervalID);
  }, [userInfo]);

  const onHide = () => {
    setShow(false);
  }

  const handleOpenPostModal: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    setShow(true);
  }

  const handleAddFriend = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    if (Object.keys(userInfo).length > 0 && user != null) { // if userInfo is fetched
      axios.post(`/users/${userInfo?.id}/friend_requests/`, {
        "sender_id": user.user_id
      }, getAuthHeader())
      .then((response) => {
        setIsFriend("pending");
      })
    }
  }

  return (
    <MainLayout>
      <div className="px-2 sm:p-12 sm:px-16 flex flex-col gap-6 sm:grid sm:grid-cols-8 sm:gap-12 sm:overflow-y-hidden">
        <div className="flex flex-col gap-2 sm:gap-4 sm:col-span-3">
          <div 
            className="flex flex-row gap-4 sm:gap-8 sm:col-span-2 mt-3 sm:mt-0"
          >
            <div className="hover:cursor-pointer h-24 w-24 sm:h-31 sm:w-31 rounded-full relative"
              onClick={() => setSelectedGame(null)}
            >
              {(userInfo?.is_duel_winner) && <div className="absolute sm:h-full sm:w-full h-16 w-16 -top-6 -left-2 sm:-top-14 sm:-left-4 -rotate-12">
                <img src={HiloCrown} alt="" className="w-full h-full object-cover" />
              </div>}
              <img src={userInfo?.profile_pic} alt="" className="w-full h-full object-cover overflow-hidden rounded-full" />
            </div>
            <div className="text-white flex flex-col gap-1.5">
              <div className="flex flex-row gap-4 items-center">
                <span className="text-lg sm:text-[24px]">
                  {userInfo?.username}
                </span>
                {(String(userInfo["discord_id"]).length > 0) && <a 
                  className="w-8 h-6 overflow-hidden hover:transform hover:-translate-y-1 transition duration-300"
                  href={`https://www.discordapp.com/users/${userInfo["discord_id"]}`}
                >
                  <img
                    src="https://cdn.prod.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"
                    className="w-full h-full object-cover"
                  />
                </a>}
                {(userInfo["steam_id64"].length > 0) && <a
                  className="w-8 h-8 overflow-hidden hover:transform hover:-translate-y-1 transition duration-300"
                  href={`https://steamcommunity.com/profiles/${userInfo["steam_id64"]}`}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Steam_icon_logo.svg/480px-Steam_icon_logo.svg.png"
                    className="w-full h-full object-cover"
                  />
                </a>}
              </div>
              <span className="text-[12px] sm:text-lg flex flex-row gap-2 items-center">
                <IconContext.Provider value={{color: "#5CE1E6"}}>
                 <FaCircle style={{ fontSize: '10px' }} />
                </IconContext.Provider>
                <span className="text-[10px] sm:text-[14px]" style={{ color: '#C4C4C4' }}>Team Alpha</span>
                <span className="relative">
                  <div className="h-5 w-6 overflow-hidden">
                    <img src={VibesIcon} className="h-full w-full object=cover" />
                  </div>
                  <div className="text-[14px] text-[#a8b8f4] font-sans absolute -right-[22px] top-1">
                    {userInfo?.vibes}
                  </div>
                </span>
              </span>
              <div>
                <span className="sm:text-[18px] font-sans font-bold align-bottom">
                  {time}
                  <span className="ml-3 text-[14px] font-normal">
                    local time
                  </span>
                </span>
              </div>
              {(userInfo?.id !== user?.user_id) &&
              <>
                {(isFriend === "pending") &&
                <button
                  className="text-[14px] bg-[#4B5859] border-none text-[#D7FBFF] py-0.5 rounded-lg max-w-24 font-light"
                  disabled={true}
                >
                  Requested
                </button>}
                {(isFriend === "not_friend") && 
                <button
                  className="text-[14px] bg-[#238590] hover:bg-[#7f9495] border-none text-[#D7FBFF] py-0.5 rounded-lg max-w-28 font-light"
                  onClick={handleAddFriend}
                >
                  Add friend
                </button>}
                {(isFriend === "friend") &&
                <button
                  className="text-[14px] bg-[#4B5859] border-none text-[#D7FBFF] py-0.5 rounded-lg max-w-24 font-light"
                  disabled={true}
                >
                  Friends
                </button>}
              </>}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[14px] sm:text-[16px] text-white font-thin">
                {userInfo?.bio}
            </p>
          </div>
          <button
            className="flex flex-col"
            onClick={(e) => {
              e.preventDefault();
              setSelectedGame(null);
            }}
          >
            <p className="text-[14px] sm:text-[16px] text-white hover:underline hover:cursor-pointer">
              Open Commend Wall
            </p>
          </button>
          <SingleSelectGameList
            games={games}
            setSelectedGame={setSelectedGame}
            pageNum={pageNum}
            setPageNum={setPageNum}
            setHasMore={setHasMore}
            setTrigger={setPostTrigger}
            trigger={postTrigger}
          />
          <WidgetList
            selectedGame={selectedGame}
            userInfo={userInfo}
          />
        </div>
        <div className="profile-posts sm:col-span-5 flex flex-col gap-12 sm:max-h-[100vh] z-20">
          <SessionList
            handleOpenPostModal={handleOpenPostModal}
            userInfo={userInfo}
            trigger={postTrigger}
            setTrigger={setPostTrigger}
            setSelectedGame={setSelectedGame}
            selectedGame={selectedGame}
            hasMore={hasMore}
            setHasMore={setHasMore}
            pageNum={pageNum}
            setPageNum={setPageNum}
          />
        </div>
      </div>
      <PostModal
        show={show}
        onHide={onHide}
        setTrigger={setPostTrigger}
        trigger={postTrigger}
        userInfo={userInfo}
        setPageNum={setPageNum}
        mentions={mentions}
        games={userGames}
        selectedGame={selectedUserGame}
        setSelectedGame={setSelectedUserGame}
      />
    </MainLayout>
  );
}

export default Profile;
