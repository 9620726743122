import { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import AuthContext from "../components/AuthProvider";
import GameSelector from '../components/GameSelector';
import AddGameSearch from '../components/AddGameSearch';

import PlaceholderPhoto from "../assets/add-photo.png";
import { IoIosArrowDown } from 'react-icons/io';

import { 
  DropdownContainer,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
} from "../components/Dropdown"
import { getReadableTimezones, getTimezoneChoice, getMainTimeZone } from "../timezones"; 
import { getAuthHeader, clearQueryParams } from "../utils";
import { IconContext } from "react-icons/lib";
import { AiOutlineLoading } from "react-icons/ai";
import { Game } from "../types/Game";
import { BaseHeaderType } from "../types/Auth";
import MainLayout from "../layout/MainLayout";

const Settings = () => {
  const {user} = useContext(AuthContext);
  const [next, setNext] = useState(false);

  const timezones = getReadableTimezones();
  const [updateInfo, setUpdateInfo] = useState({
    username: "",
    bio: "",
    playerId: "",
    discordUsername: "",
    twitchUsername: "",
    referralCode: "",
    hsbgBattletag: "",
  });
  const [selectedPic, setSelectedPic] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState(PlaceholderPhoto);
  const [showImgErr, setShowImgErr] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [savingProfile, setSavingProfile] = useState(false);

  const [games, setGames] = useState<Game[]>([]);
  const [newGames, setNewGames] = useState<Game[]>([]);
  const [selectedGames, setSelectedGames] = useState<Game[]>([]);
  const [savingGames, setSavingGames] = useState(false);
  
  const [discordDisabled, setDiscordDisabled] = useState(false);
  const [discordLinked, setDiscordLinked] = useState(false);
  const [playsHSBG, setPlaysHSBG] = useState(false);
  const [twitchDisabled, setTwitchDisabled] = useState(false);
  const [twitchLinked, setTwitchLinked] = useState(false);
  const [copiedReferral, setCopiedReferral] = useState(false);

  const [trigger, setTrigger] = useState(false);

  const dropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hashParams = window.location.hash.substring(1);
    const params = new URLSearchParams(hashParams);

    const accessToken = params.get("access_token");

    if (accessToken) {
      setDiscordDisabled(true);
      if (user != null) {
        axios.post(`/users/${user.user_id}/discord/`, {
          access_token: accessToken
        }, getAuthHeader())
        .then((response) => {
          setDiscordLinked(true);
          setUpdateInfo({
            ...updateInfo,
            discordUsername: response.data["discord_username"],
            twitchUsername: response.data["twitch_username"]
          });
        })
        .catch((err) => {
          alert('Unable to link Discord account');
        })
        .finally(() => {
          setDiscordDisabled(false);
          clearQueryParams();
          setTrigger(!trigger);
        })
      }
    }
  }, [])
  
  useEffect(() => {
    if (user != null) {
      axios.get(`/users/${user.user_id}`, getAuthHeader())
      .then((response) => {
        setUpdateInfo({
          username: response.data["username"],
          bio: response.data["bio"],
          playerId: response.data["steam_id"],
          discordUsername: response.data["discord_username"],
          twitchUsername: response.data["twitch_username"],
          referralCode: response.data["referral_code"],
          hsbgBattletag: response.data["hsbg_battletag"]
        });
        if (response.data["discord_username"].length > 0)
          setDiscordLinked(true);
        setSelectedTimezone(getMainTimeZone(response.data['user_timezone']))
        setPreviewUrl(response.data['profile_pic']); 
      });
    }
  }, [trigger]);

  useEffect(() => {
    if (user != null) {
      axios.get(`/games/?user_id=${user.user_id}`, getAuthHeader())
      .then((response) => {
        setSelectedGames(response.data.map((item: Game) => item.id));
        setNewGames(response.data);
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i]?.title === "Hearthstone Battlegrounds")
            setPlaysHSBG(true);
        }
      });
    }
  }, []);

  const handleChange = (field: string) => (e: any) => {
    setUpdateInfo({...updateInfo, [field]: e.target.value});
  }

  const handleSteamLogin = (e: any) => {
    e.preventDefault();
    const steamBaseUrl = "https://steamcommunity.com/openid/login";
    
    const returnUrl = `${process.env.REACT_APP_FRONTEND_URL}/steam-callback?auth=true`;
    
    const steamLoginUrl = `${steamBaseUrl}?` + new URLSearchParams({
      "openid.ns": "http://specs.openid.net/auth/2.0",
      "openid.claimed_id": "http://specs.openid.net/auth/2.0/identifier_select",
      "openid.identity": "http://specs.openid.net/auth/2.0/identifier_select",
      "openid.return_to": returnUrl,
      "openid.realm": process.env.REACT_APP_FRONTEND_URL || "",
      "openid.mode": "checkid_setup",
    }).toString();
    
    window.location.href = steamLoginUrl;
  };

  const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0)
      return;
    const file = e.target.files[0];
    const maxFileSize = 10 * 1024 * 1024; // 10 MB in bytes

    if (!file || !file.type.startsWith('image/') || file.size > maxFileSize) {
      setShowImgErr(true);
      return;
    } else {
      setShowImgErr(false);
    }

    setSelectedPic(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        setPreviewUrl(reader.result);
      }
    }
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowDrop(!showDrop);
  }

  const handleCloseDropdown = (e: MouseEvent) => {
    if (showDrop && !dropRef.current?.contains(e.target as Node)) {
      setShowDrop(false);
    }
  }
  document.addEventListener('mousedown', handleCloseDropdown);

  const handleChangeTimezone = (timezone: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setSelectedTimezone(timezone);
    setShowDrop(false);
  }

  const handleDiscordLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let baseUrl = "https://discord.com/oauth2/authorize?";
    const discordOauthUrl = `${baseUrl}` + new URLSearchParams({
      "response_type": "token",
      "client_id": `${process.env.REACT_APP_DISCORD_HILO_CLIENT_ID}`,
      "scope": "identify",
      "redirect_uri": `${process.env.REACT_APP_FRONTEND_URL}/settings`
    });
    window.location.href = discordOauthUrl;
  }

  const handleTwitchLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let baseUrl = "https://id.twitch.tv/oauth2/authorize?";
    const twitchOauthUrl = `${baseUrl}` + new URLSearchParams({
      "response_type": "token",
      "client_id": `${process.env.REACT_APP_TWITCH_CLIENT_ID}`,
      "scope": "user:read:email",
      "redirect_uri": `${process.env.REACT_APP_FRONTEND_URL}/settings`
    })
    window.location.href = twitchOauthUrl;
  }

  const handleSaveProfile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSavingProfile(true);

    const formData = new FormData();

    if (selectedTimezone != null && selectedTimezone.length > 0) {
      const userTimezone = getTimezoneChoice(selectedTimezone);
      formData.append('user_timezone', userTimezone);
    }

    if (updateInfo["playerId"].length > 0)
      formData.append('steam_id', updateInfo["playerId"]);

    if (updateInfo["bio"].length > 0)
      formData.append('bio', updateInfo["bio"]);

    if (updateInfo["username"].length > 0)
      formData.append('username', updateInfo["username"]);

    if (updateInfo["hsbgBattletag"].length > 0)
      formData.append('hsbg_battletag', updateInfo["hsbgBattletag"])
  
    if (selectedPic != null && previewUrl !== PlaceholderPhoto)
      formData.append('file', selectedPic);
    
    if (newGames.length > 0)
      formData.append('games_played', JSON.stringify(newGames));

    let baseHeader: BaseHeaderType = getAuthHeader();
    baseHeader["headers"]["Content-Type"] = "multipart/form-data";

    if (user != null) {
      axios.patch(`/users/${user.user_id}/`, formData, baseHeader)
      .then((response) => {
        // stop saving icon
        setSavingProfile(false);
        window.location.href = `${window.location.origin}`;
      })
      .catch((error) => {
        setSavingProfile(false);
        if (error.response) {
          if (error.response && error.response.status === 400) {
            const errorMessages = error.response.data.errors; // Assuming "errors" is an array of strings
            if (Array.isArray(errorMessages)) {
              alert("Error updating profile:\n" + errorMessages.join("\n"));
            } else {
              alert("Error updating profile. Please try again.");
            }
          } else if (error.response) {
            alert(`Unexpected error occurred: ${error.response.status}`);
          } else {
            alert("Unable to connect to the server. Please check your internet connection.");
          }
        }
      });
    }
  }

  return (
    <MainLayout>
      <div className="flex items-center justify-center mt-12">
        <div className="w-full bg-transparent border-4 border-hiloLoginBorder rounded-login shadow mx-10 px-12 sm:mb-20 py-12 justify-center relative">
          {(!next) &&
          <form className="sm:space-y-0 space-y-8 grid grid-cols-1 sm:flex sm:flex-row sm:gap-16 justify-items-center justify-center mb-24">
            <div className="sm:flex sm:flex-col sm:items-center sm:justify-center text-center" >
              <div className="h-24 w-24 sm:h-60 sm:w-60 mb-4 mx-auto sm:mb-12 rounded-full overflow-hidden">
                <img src={previewUrl} alt="" className="w-full h-full object-cover" />
              </div>
              <label htmlFor="upload-pfp" className="max-w-40 text-center">
                <p className="bg-gray-400 text-white py-2 px-12 rounded-full hover:cursor-pointer">
                  Upload
                </p>
              </label>
              {(showImgErr) && 
                <p className="mt-2 sm:mt-4 text-[10px] sm:text-[13px] text-red-500">Please upload a valid image that is under 10MB in size</p>
              }
              <input type="file" id="upload-pfp" onChange={handleUploadChange} className="text-white hidden justify-self-center"/>
            </div>
            <div className="flex flex-col sm:flex-row gap-8">
              <div className="sm:flex sm:flex-col sm:gap-4 flex flex-col gap-4">
                <div className="flex flex-row gap-2">
                  {(updateInfo["playerId"]?.length === 0) ? 
                  <button
                    className="flex flex-1 items-center justify-center text-white text-[18px] font-poppins gap-2 font-extrabold w-full border-[1px] rounded-3xl py-[12px] px-[24px] hover:bg-slate-500"
                    type="button"
                    onClick={handleSteamLogin}
                  >
                    Link Steam account
                    <div className="h-8 w-8 rounded-full overflow-hidden">
                      <img 
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Steam_icon_logo.svg/2048px-Steam_icon_logo.svg.png"
                        alt="Steam"
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </button> : (
                  <div className="text-white font-sans text-[18px]">
                    Steam account linked: {updateInfo["playerId"]}
                  </div>
                  )}
                  <button
                    className="flex items-center justify-center text-white text-[16px] font-poppins gap-2 font-extrabold w-[50%] border-[1px] rounded-3xl py-[12px] px-[24px] hover:bg-slate-500"
                    type="button"
                    onClick={() => {
                      if (updateInfo['referralCode'] != null && updateInfo['referralCode']?.length > 0) {
                        let userReferralLink = `${window.location.origin}/login?refer=${updateInfo['referralCode']}`;
                        navigator.clipboard.writeText(userReferralLink);
                        setCopiedReferral(true);
                      }
                    }}
                  >
                    {(copiedReferral) ? "Copied!" : "Copy Referral Link"}
                  </button>
                  {/* <p className="text-[16px] font-poppins text-white">
                    DOTA 2 Player ID
                  </p>
                  <input type="text" name="playerId" placeholder="DOTA 2 Player ID"
                    onChange={handleChange("playerId")}
                    value={updateInfo["playerId"]}
                    className="font-poppins sm:pl-6 bg-transparent bg-login-user border border-[#A1A1A1] text-white text-base font-light sm:text-[20px] rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 sm:pb-5 sm:mr-60" /> */}
                </div>
                <div>
                  <p className="text-[16px] font-poppins text-white">
                    Username
                  </p>
                  <textarea name="change-username" id="change-username" placeholder=""
                    onChange={handleChange("username")}
                    value={updateInfo["username"]}
                    rows={1}
                    className="resize-none font-poppins sm:pl-6 bg-transparent bg-login-user border border-[#A1A1A1] text-white text-base font-light sm:text-[20px] rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full mr-60" />
                </div>
                <div>
                  <p className="text-[16px] font-poppins text-white">
                    Bio
                  </p>
                  <textarea name="bio" id="bio" placeholder="The best pokemon master that ever was"
                    onChange={handleChange("bio")}
                    value={updateInfo["bio"]}
                    className="resize-none font-poppins sm:pl-6 bg-transparent bg-login-user border border-[#A1A1A1] text-white text-base font-light sm:text-[20px] rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 sm:pb-5 mr-60" />
                </div>
                <div className="flex flex-col sm:flex-row gap-4 items-end">
                  <div className="mx-auto sm:mx-0">
                    <p className="text-[16px] font-poppins text-white">
                      Timezone
                    </p>
                    <DropdownContainer className="bg-transparent border-gray-400 text-white text-base sm:text-lg rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full text-center sm:text-left" ref={dropRef}>
                      <DropdownButton onClick={toggleDropdown}>
                        <span style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "3px"}}>
                          {(selectedTimezone === "") ? "Select your Timezone" : selectedTimezone}
                          <IoIosArrowDown />
                        </span>
                      </DropdownButton>
                      <div>
                        <DropdownMenu show={showDrop}>
                          {(timezones).map((timezone) => {
                            return (
                              <DropdownItem
                                onClick={handleChangeTimezone(timezone)}
                              >
                                {timezone}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </div>
                    </DropdownContainer>
                  </div>
                  {(playsHSBG) && <div className="mx-auto sm:mx-0">
                    <p className="text-[16px] font-poppins text-white">
                      HSBG Battletag
                    </p>
                    <textarea name="change-hsbg-battletag" id="change-hsbg-battletag"
                      onChange={handleChange("hsbgBattletag")}
                      value={updateInfo["hsbgBattletag"]}
                      rows={1}
                      placeholder="Player#1234"
                      className="resize-none font-poppins sm:pl-6 bg-transparent bg-login-user border border-[#A1A1A1] text-white text-base font-light sm:text-[20px] rounded-3xl focus:ring-blue-500 focus:border-blue-500 block mr-60"
                    />
                  </div>}
                </div>
                <div className="flex flex-row gap-4">
                  <div className="flex flex-row gap-2 mx-auto sm:mx-0">
                    {(!discordLinked) ? <button
                      className="text-white flex items-center gap-2 bg-[#5765f2] hover:bg-[#7289DA] px-4 py-2 rounded-3xl transition-transform duration-200 hover:translate-y-[-4px]"
                      onClick={handleDiscordLogin}
                      disabled={discordDisabled}
                    >
                      <div className="w-8 h-6 overflow-hidden">
                        <img 
                          src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/discord-white-icon.png"
                          className="w-full h-full object-cover"
                          style={{"color": "white"}}
                        />
                      </div>
                      Link your Discord
                    </button> : (
                      <span 
                        className="text-white flex items-center gap-2 bg-[#5765f2] hover:bg-[#7289DA] px-4 py-2 rounded-3xl transition-transform duration-200 hover:translate-y-[-4px]"
                      >
                        <div className="w-8 h-8 overflow-hidden">
                          <img 
                            src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/discord-white-icon.png"
                            className="w-full h-full object-cover"
                            style={{"color": "white"}}
                          />
                        </div>
                        {updateInfo["discordUsername"]}
                      </span>
                    )}
                  </div>
                  {/* <div className="mx-auto sm:mx-0">
                    {(!twitchLinked) ? <button
                      className="text-white flex items-center gap-2 bg-[#6441A5] hover:bg-[#8956FB] px-4 py-2 rounded-3xl transition-transform duration-200 hover:translate-y-[-4px]"
                      onClick={handleTwitchLogin}
                      disabled={twitchDisabled}
                    >
                      <div className="w-8 h-8 overflow-hidden">
                        <img 
                          src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/twitch-white-icon.png"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      Link your Twitch
                    </button> : (
                      <span
                        className="text-white flex items-center gap-2 bg-[#6441A5] hover:bg-[#8956FB] px-4 py-2 rounded-3xl transition-transform duration-200 hover:translate-y-[-4px]"
                      >
                        <div className="w-8 h-6 overflow-hidden">
                          <img 
                            src="https://static-00.iconduck.com/assets.00/twitch-icon-2048x2048-sn82glxi.png"
                            className="w-full h-full object-cover"
                            style={{"color": "white"}}
                          />
                        </div>
                        {updateInfo["twitchUsername"]}
                      </span>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </form>}

          {(next) && 
            <GameSelector games={games} setGames={setGames}
              selectedGames={selectedGames} setSelectedGames={setSelectedGames}
              newGames={newGames} setNewGames={setNewGames}
            />
          }
          {(next) && 
            <AddGameSearch newGames={newGames} setNewGames={setNewGames}/>
          }
          {(!next) &&
          <div className="absolute left-8 bottom-8">
            <button
              className="text-white py-2 sm:py-2 px-4 sm:px-12 rounded-full text-sm sm:text-lg font-poppins border-2"
              onClick={(e) => {
                e.preventDefault();
                setNext(!next);
              }}
            >
              Select games
            </button>
          </div>}
          {(next) && 
          <div className="absolute left-8 bottom-8">
            <button
              className="text-white py-2 sm:py-2 px-4 sm:px-12 rounded-full text-sm sm:text-lg font-poppins border-2"
              onClick={(e) => {
                e.preventDefault();
                setNext(!next);
              }}
            >
             Previous 
            </button>
          </div>}
          {(next) && 
          <div className="absolute right-8 bottom-8 flex flex-row gap-16 items-start">
            <div>
              {(savingGames) && <span className="absolute animate-spin h-12 mr-3">
                <IconContext.Provider
                  value={{ color: "#FFF" }}
                >
                  <AiOutlineLoading size={48} />
                </IconContext.Provider>
              </span>}
              <button
                className="bg-[#21818C] text-white py-2 px-12 rounded-full sm:text-lg font-poppins"
                onClick={handleSaveProfile}
              >
                Confirm
              </button>
            </div>
          </div>}
          {(!next) &&
          <div className="absolute right-8 bottom-8 flex flex-row gap-16 items-start">
            <div>
              {(savingProfile) && <span className="absolute animate-spin h-12 mr-3">
                <IconContext.Provider
                  value={{ color: "#FFF" }}
                >
                  <AiOutlineLoading size={48} />
                </IconContext.Provider>
              </span>}
            </div>
            <button
              className="bg-[#21818C] text-white py-2 px-6 sm:px-12 rounded-full sm:text-lg font-poppins"
              onClick={handleSaveProfile}
            >
              Save
            </button>
          </div>}
        </div>
      </div>
    </MainLayout>
  );
}

export default Settings;